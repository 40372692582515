import { getWithAuth } from 'api/config';

import { dateIsoWithTimeZone } from 'helpers/date';

export interface IAnalyticMembers {
  count: number;
  user: {
    id: number;
    name: string;
    email: string;
  };
}

export interface IAnalyticBoards {
  count: number;
  board: {
    id: number;
    name: string;
    url_id: string;
    type: string;
  };
}

export interface ITeamAnalyticsResponse<T> {
  analytics: T[];
  updated_at: string;
}

export type IAnalyticsType = 'members' | 'boards';

export const getTeamAnalytics = async (
  teamUrlId: string,
  startDate: Date,
  endDate: Date,
  sort: string = 'desc',
  analyticsType: IAnalyticsType = 'members'
) => {
  // create iso ISO 8601
  const startDateEncode = encodeURIComponent(dateIsoWithTimeZone(startDate));
  const endDateEncode = encodeURIComponent(dateIsoWithTimeZone(endDate));

  const sortBy = sort === 'desc' ? 'count' : '-count';
  const url = `${
    import.meta.env.VITE_DEV_BASE_URL
  }/v1/teams/${teamUrlId}/analytics/${analyticsType}?start=${startDateEncode}&end=${endDateEncode}&sort=${sortBy}`;
  return await getWithAuth(url);
};

export interface ITeamAnalyticsDetailResponse {
  analytics: Array<{
    count: number;
    time: string;
  }>;
  updated_at: string;
}

export const getTeamAnalyticsDetail = async (
  teamUrlId: string,
  startDate: Date,
  endDate: Date,
  userId: string,
  analyticsType: IAnalyticsType = 'members',
  interval: string = '3hour'
) => {
  // create iso ISO 8601
  const startDateEncode = encodeURIComponent(dateIsoWithTimeZone(startDate));
  const endDateEncode = encodeURIComponent(dateIsoWithTimeZone(endDate));

  const url = `${
    import.meta.env.VITE_DEV_BASE_URL
  }/v1/teams/${teamUrlId}/analytics/${analyticsType}/${userId}/overviews?start=${startDateEncode}&end=${endDateEncode}&interval=${interval}`;
  return await getWithAuth(url);
};

export const getTeamAnalyticsTopBoards = async (
  teamUrlId: string,
  startDate: Date,
  endDate: Date,
  userId: string,
  analyticsType: IAnalyticsType = 'members',
  limit: number = 20
) => {
  // create iso ISO 8601
  const startDateEncode = encodeURIComponent(dateIsoWithTimeZone(startDate));
  const endDateEncode = encodeURIComponent(dateIsoWithTimeZone(endDate));
  const dataDetail = analyticsType === 'members' ? 'boards' : 'members';

  const url = `${
    import.meta.env.VITE_DEV_BASE_URL
  }/v1/teams/${teamUrlId}/analytics/${analyticsType}/${userId}/${dataDetail}?start=${startDateEncode}&end=${endDateEncode}&limit=${limit}`;
  return await getWithAuth(url);
};
